.formSettings {
  padding: 30px;
}
h4 {
  font-size: 16px;
}
.saveSettingsButton {
  display: inline-block;
  font-size: 10pt;
  font-weight: 500;
  border-radius: 12px;
  border-radius: 10px;
  border-width: 1px;
  border-color: #ced4da !important;
  box-shadow:none !important;
}
