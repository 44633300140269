/* SUBMISSION BOX */
.overlayContainer {
	border-width: 2px;
	border-style: solid;
	border-color: #ECECEB;
	position: fixed;
	border-radius: 10px;
	float: right;
	background: #ffffff;
	z-index: 9999;
}
