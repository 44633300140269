body {
  padding: 0;
  min-width: 800px;
}
.wrapper {
  display: flex;
  flex-flow: column wrap;
  font-weight: bold;
  text-align: center;
  height: 100vh;

}
.wrapper > * {
  padding: 10px;
  flex: 1 100%;
}

/* main container - container nav and navigation controller */
.main {
  display: flex;
  flex-flow: row wrap;
  text-align: left;
  background: white;
  flex: 1 0;
  padding: 0;
  order: 1;
}

/* #headers stuff */
.header {
  z-index: 999;
  width: auto;
  max-height: 90px;
  box-shadow: 0px 2px 0 0 rgba(138, 139, 138, 0.35);
  margin: 0;
}
.headerUserName {
  margin-right: 30px;
  color: gray;
}
.logo {
  background: url('./../images/logo.png') ;
  background-size:     contain;                      /* <------ */
  background-repeat:   no-repeat;
  background-position: center center;
  float: left;
  width: 159px;
  height: 69px;
  margin-left: 20px;
}

/* NavBar sutff */
.nav { order: 0; }
.navbar {
  z-index: 998;
  min-width: 180px;
  margin-top: 2px;
  justify-content: flex-start;
  align-items: flex-start;
  background: white;
  box-shadow: 2px 0 0 0 rgba(138, 139, 138, 0.35);
}
.nav-item.active {
  text-decoration: underline;
  font-weight:500;
}
.nav-item:hover {
  text-decoration: underline;
  cursor: pointer;
}
.nav-item { font-weight: lighter; }
.navbar .nav-item,h3 { margin-left: 10px; }
.navbar h3 { margin-top: 5px; }
.navbar .nav-item { margin-top: 15px; }
.nav-span { flex-grow: 99; }
.navLogoutButton, .navLogoutButton:hover, .navLogoutButton:active:focus {
  background-color: transparent !important;
  border: none;
  color: black !important;
  margin-left: -15px;
  box-shadow: none !important;
}
/* Main app content */
.AppPageContainer{
  position: relative;
  flex: 1 0;
}
