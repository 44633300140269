.loading {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0,0,0,.5);
    z-index: 99999;
    -webkit-animation: fadein .3s ease-in ;
}
.loading.hide {
    -webkit-animation: fadeout .3s ease-out ;
    opacity: 0;
    visibility: hidden; 
}
.loading-wheel {
    -webkit-animation: spin 1s linear infinite;
    background-image : url('./../images/logo-loading.png');
    position: absolute;
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover;
    position: absolute;
    width: 100px;
    height: 100px;
    top: 50%;
    left: 50%;
    margin-top: -50px;
    margin-left: -50px;
}
@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0);
    }
    100% {
        -webkit-transform: rotate(-360deg);
    }
}
@-webkit-keyframes fadein {
    0% { opacity: 0; }
    100% { opacity: 1; }
}
@-webkit-keyframes fadeout {
    0% { opacity: 1; }
    100% { opacity: 0; }
}
