.filterContainer {
    margin: 0;
    max-width: 100%;
}
.yearButton {
  margin-top: 2px;
}
.yearButton, .monthButton{
  margin-right: 15px !important;
  border-radius: 15px !important;
  padding: 0px 15px 0px 15px !important;
  background-color: white;
  color: black;
  font-size: 14px;
  font-weight: 500;
  border-color: #F0F0F0;
  box-shadow: none !important;
}
.selectionBar {
  padding-top: 8px;
  padding-bottom: 8px;
  border-bottom-color: #D7D7D7;
  border-bottom-width: 2px;
  border-bottom-style: solid;
}
.goButton {
  font-size: 10pt;
  font-weight: 500;
  border-radius: 12px;
  padding: 0px 10px 0px 10px;
  border-radius: 10px;
  border-width: 1px;
  border-color: #ced4da !important;
  box-shadow:none !important;
}
.btn-primary:not(:disabled):not(.disabled).active, .btn-primary:hover, .btn-primary:not(:disabled):not(.disabled):active, .show>.btn-primary.dropdown-toggle {
  background-color: #EAA39D;
  border-color: #F0F0F0;
  color: black;
}

/* Table */
.ReactTable {
  border: none;
}
