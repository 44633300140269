.authBox {
  width: 350px;
  margin-top: 100px;
}
.authLogo {
  height: 70px;
  margin-bottom: 20px;
}
.authLabel {
  margin-bottom: 15px;
}
.inputFields {
  height: 60px;
  font-weight: 500;
  border-radius: 10px;
  border-width: 2px;
  padding-left: 20px;
}
.inputFields::placeholder {
  color: #BBBBBB;
}
.inputFields:active,  .inputFields:focus {
  box-shadow: 0 0 4px 2px #EAA39D !important;
  border-color: #BBBBBB;
}
.loginButton, .loginButton:hover, .loginButton:hover:disabled, .navLogoutButton:active:focus {
  height: 60px;
  font-weight: 500;
  font-size: 1em;
  color: black !important;
  border-radius: 10px;
  border-width: 2px;
  background-color: #EAA39D !important;
  border-color: #ced4da !important;
  box-shadow: none !important;
}
.loginButton:hover {
  background-color: #e88178 !important;
}
