.logContainer {
  background-color: #EDECED;
}
.logFormContainer {
  margin-left: 15px;
  margin-right: 15px;
}
.formLabel {
  padding-top: 15px;
  margin-left: 10px;
  margin-bottom: 10px;
	font-size: 20px !important;
}
a.nav-item.nav-link {
  color: black;
  font-weight: 600;
  border-color:  #e9ecef #e9ecef #dee2e6 !important;
  border-width: 1px !important;
  border-style: solid;
  border-top-left-radius: .4rem;
  border-top-right-radius: .4rem;
}
a.nav-item.nav-link.active {
  text-decoration: none;
  border-color:  #e9ecef #e9ecef transparent !important;
}
.nav-tabs {
  border-bottom: none !important;
  border-width: 0px;
}
/* child box container */
.childBox {
  border-color:  #e9ecef #e9ecef #dee2e6 !important;
  border-width: 1px !important;
  border-style: solid;
  padding: 20px;
  background-color: white;
}
/* Child preferences */
.logFormContainer h6 {
  font-size: 12px;
  font-weight: 500;
}
.logLeftMarginLabel {
  margin-left: -30px;
  margin-top: 20px;
}
.logFormContainer input,.logFormContainer select {
  font-size: 12px;
  font-weight: 500;
}
.logFormContainer .form-group {
  margin-bottom: 5px;
}
/* Image Gallery */
.imageGallery {
  border-radius: 10px;
  border-width: 2px;
  border-style: solid;
  border-color: #dddddd;
}
.imageContainer {
  margin: 15px;
  width: 200px;
  height: 200px;
  border-radius: 10px;
  border-width: 2px;
  border-style: solid;
  border-color: #eeeeee;
}
.loadingIcon {
  margin-left: 50px;
  margin-top: 50px;
}
.imageDeleteButton {
  width: 20px !important;
  height: 20px;
  margin-left: -10px;
  margin-top: -10px;
  position: absolute;
  background-color: transparent !important;
  color: red;
}
.imageDeleteButton:hover {
  color: black;
}
.imageObject {
  position: absolute;
  width: 190px;
  margin: 2.5px;
  height: 190px;
}

/* File selector */
.fileSelector {
  /* border-radius: 10px;
  border-width: 2px;
  border-style: solid;
  border-color: #dddddd; */
}
