.logGaugeBackground {
  	background-color: #eeeeee;
  	padding: 15px;
    margin: 0 !important;
}
.logGaugeContainer {
}
.logGaugeContainerBackground {
	border-radius: 10px;
	padding: 10px;
	background-color: #ffffff;
}
.logGaugeBar {
  margin-top: 10px;
	border-radius: 10px;
  height: 100px;
	background-color: #dddbdb;
}
.logGaugeBarText {
  position: relative;
}
.logGaugeUsedBackground {
  position: absolute;
	border-radius: 10px;
  background-color: #dcaba6;
  /* animation */
  -moz-transition: animateUsedBackground 1s ease-in-out;
  -o-transition: animateUsedBackground 1s ease-in-out;
  -webkit-animation: animateUsedBackground 1s ease-in-out;
  -webkit-animation-fill-mode: forwards;
}
@-webkit-keyframes animateUsedBackground {
  from {
    height: 100px;
    width: 0px;
  }
  to { height: 100px; }
}
.logGaugeMainLabel {
  padding-top: 10px;
  font-size: 43px;
  font-weight: 600;
  text-align: center;
  color: #000000;
  z-index: 99;
}
.logGaugeSecondaryLabel {
  padding-bottom: 15px;
  font-size: 17px;
  font-weight: 600;
  text-align: center;
  color: #000000;
  z-index: 99;
}
.logGaugeButtonContainer {
  margin-left: 40px;
  margin-right: 10px;
}
