body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.alert {
  z-index: 9990;
  position: absolute;
  right: 50%;
  max-width: 300px;
  width: 300px;
  top:10px;
  margin-right: -150px;
  overflow: scroll;
  max-height: 100px;
}

/* general react table  */
.ReactTable .rt-tbody .rt-tr-group:hover .tableResetPassButton,
.ReactTable .rt-tbody .rt-tr-group:hover .tableEditButton,
.ReactTable .rt-tbody .rt-tr-group:hover .tableDeleteButton{
 display: inline-block;
 font-size: 10pt;
 font-weight: 500;
 border-radius: 12px;
 padding: 2px 10px 2px 10px;
 border-radius: 10px;
 border-width: 1px;
 border-color: #ced4da !important;
 box-shadow:none !important;
}
.ReactTable .rt-tbody .rt-tr-group:hover .tableResetPassButton {

}
.tableDeleteButton,.tableEditButton,.tableResetPassButton {
 display: none;
}
.ReactTable .rt-tbody .rt-tr-group:hover .tableEditButton,
.ReactTable .rt-tbody .rt-tr-group:hover .tableResetPassButton,
.ReactTable .rt-tbody .rt-tr-group:hover .tableDeleteButton{
 display: inline-block;
}
.ReactTable .rt-thead .rt-th, .ReactTable .rt-thead .rt-td {
  border-right: none;
  margin-top: 10px;
  margin-bottom: 10px;
  font-weight: 600;
}
.ReactTable .rt-th, .ReactTable .rt-td {
  font-weight: normal;
  text-align: center;
}
.ReactTable .rt-tbody .rt-td {
  border-right: none;
}
