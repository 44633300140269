.ReactNavigationControllerView,
.ReactNavigationControllerViewContent {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow-y: auto;
}

.ReactNavigationControllerView {
  display: flex;
}
.ReactNavigationController {
  position: relative;
  flex: 1 0;
}
.ReactNavigationControllerViewContent {
  color: white;
  display: flex;
  flex-direction: column;
}

.loading {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0,0,0,.5);
    z-index: 99999;
    -webkit-animation: fadein .3s ease-in ;
}
.loading.hide {
    -webkit-animation: fadeout .3s ease-out ;
    opacity: 0;
    visibility: hidden; 
}
.loading-wheel {
    -webkit-animation: spin 1s linear infinite;
    background-image : url(/static/media/logo-loading.25f2fe44.png);
    position: absolute;
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover;
    position: absolute;
    width: 100px;
    height: 100px;
    top: 50%;
    left: 50%;
    margin-top: -50px;
    margin-left: -50px;
}
@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0);
    }
    100% {
        -webkit-transform: rotate(-360deg);
    }
}
@-webkit-keyframes fadein {
    0% { opacity: 0; }
    100% { opacity: 1; }
}
@-webkit-keyframes fadeout {
    0% { opacity: 1; }
    100% { opacity: 0; }
}

/* SUBMISSION BOX */
.overlayContainer {
	border-width: 2px;
	border-style: solid;
	border-color: #ECECEB;
	position: fixed;
	border-radius: 10px;
	float: right;
	background: #ffffff;
	z-index: 9999;
}

.logContainer {
  background-color: #EDECED;
}
.logFormContainer {
  margin-left: 15px;
  margin-right: 15px;
}
.formLabel {
  padding-top: 15px;
  margin-left: 10px;
  margin-bottom: 10px;
	font-size: 20px !important;
}
a.nav-item.nav-link {
  color: black;
  font-weight: 600;
  border-color:  #e9ecef #e9ecef #dee2e6 !important;
  border-width: 1px !important;
  border-style: solid;
  border-top-left-radius: .4rem;
  border-top-right-radius: .4rem;
}
a.nav-item.nav-link.active {
  text-decoration: none;
  border-color:  #e9ecef #e9ecef transparent !important;
}
.nav-tabs {
  border-bottom: none !important;
  border-width: 0px;
}
/* child box container */
.childBox {
  border-color:  #e9ecef #e9ecef #dee2e6 !important;
  border-width: 1px !important;
  border-style: solid;
  padding: 20px;
  background-color: white;
}
/* Child preferences */
.logFormContainer h6 {
  font-size: 12px;
  font-weight: 500;
}
.logLeftMarginLabel {
  margin-left: -30px;
  margin-top: 20px;
}
.logFormContainer input,.logFormContainer select {
  font-size: 12px;
  font-weight: 500;
}
.logFormContainer .form-group {
  margin-bottom: 5px;
}
/* Image Gallery */
.imageGallery {
  border-radius: 10px;
  border-width: 2px;
  border-style: solid;
  border-color: #dddddd;
}
.imageContainer {
  margin: 15px;
  width: 200px;
  height: 200px;
  border-radius: 10px;
  border-width: 2px;
  border-style: solid;
  border-color: #eeeeee;
}
.loadingIcon {
  margin-left: 50px;
  margin-top: 50px;
}
.imageDeleteButton {
  width: 20px !important;
  height: 20px;
  margin-left: -10px;
  margin-top: -10px;
  position: absolute;
  background-color: transparent !important;
  color: red;
}
.imageDeleteButton:hover {
  color: black;
}
.imageObject {
  position: absolute;
  width: 190px;
  margin: 2.5px;
  height: 190px;
}

/* File selector */
.fileSelector {
  /* border-radius: 10px;
  border-width: 2px;
  border-style: solid;
  border-color: #dddddd; */
}

.logGaugeBackground {
  	background-color: #eeeeee;
  	padding: 15px;
    margin: 0 !important;
}
.logGaugeContainer {
}
.logGaugeContainerBackground {
	border-radius: 10px;
	padding: 10px;
	background-color: #ffffff;
}
.logGaugeBar {
  margin-top: 10px;
	border-radius: 10px;
  height: 100px;
	background-color: #dddbdb;
}
.logGaugeBarText {
  position: relative;
}
.logGaugeUsedBackground {
  position: absolute;
	border-radius: 10px;
  background-color: #dcaba6;
  /* animation */
  -moz-transition: animateUsedBackground 1s ease-in-out;
  -o-transition: animateUsedBackground 1s ease-in-out;
  -webkit-animation: animateUsedBackground 1s ease-in-out;
  -webkit-animation-fill-mode: forwards;
}
@-webkit-keyframes animateUsedBackground {
  from {
    height: 100px;
    width: 0px;
  }
  to { height: 100px; }
}
.logGaugeMainLabel {
  padding-top: 10px;
  font-size: 43px;
  font-weight: 600;
  text-align: center;
  color: #000000;
  z-index: 99;
}
.logGaugeSecondaryLabel {
  padding-bottom: 15px;
  font-size: 17px;
  font-weight: 600;
  text-align: center;
  color: #000000;
  z-index: 99;
}
.logGaugeButtonContainer {
  margin-left: 40px;
  margin-right: 10px;
}

.tableDeleteButton,.tableEditButton,.tableResetPassButton {
  display: none;
}
.tableDeleteButton {
  margin-left: 15px;
}

/* ADD BUTTON IN GAUGE */
.addLogActionContainer {
	width: 35px;
}
.addLogButton {
  width: 35px;
  height: 35px;
  border-radius: 18px;
  font-size: 20px;
  padding: 0;
  box-shadow: -2px 0px 0 0 rgba(153, 153, 153, 0.35), 2px 0px 0 0 rgba(153, 153, 153, 0.35), 0px 2px 0 0 rgba(153, 153, 153, 0.35), 0px -2px 0 0 rgba(153, 153, 153, 0.35);
  background-color: #ffffff;
  color: #959494;
	text-align: center;
}
.addLogButtonText {
  text-align: center;
	margin-top: 3px;
	font-weight: 500;
	font-size: 0.8em;
}
/* SUBMISSION BOX */
.logSubmissionBox {
	top: 400px;
	width: 220px;
	height: 120px;
	right: 30px;
}
.logBoxGrandTotalContainer {
	margin-top: 10px;
}
.logBoxGrandTotalLabel {
	font-weight: light;
	font-size: 14px;
}
.logBoxGrandTotalPriceLabel {
	font-weight: 200;
	font-size: 14px;
}
.logBoxDateContainer {
	margin-top: 10px;
}
.logBoxSubmissionLabel {
	font-weight: 200;
	font-size: 14px;
	padding-right: 0px;
}
.logBoxSubmissionDateLabel {
	font-weight: 200;
	padding: 0;
	padding-right: 10px;
}
.logBoxSubmissionDateButton, .logBoxSubmissionDateButton:hover {
	border: none;
	text-decoration: underline;
	padding: 0;
	font-size: 14px;
	margin-bottom: 4px;
}
.logBoxButtonContainer {
	margin-top: 10px;
}
.logBoxSubmissionButton {
	display: inline-block;
	font-size: 10pt;
	font-weight: 500;
	border-radius: 12px;
	padding: 2px 10px 2px 10px;
	border-radius: 10px;
	border-width: 2px;
	background-color: #EAA39D;
	height: 30px;
	border-color: #ced4da !important;
	box-shadow:none !important;
}
/* Date picker */
.react-datepicker__day-name {
	display: none !important;
}
.react-datepicker__month {
	display: none;
}
.react-datepicker__header {
	width: 158px;
	height: 60px;
	border: none;
}
.react-datepicker {
	width: 160px;
	height: 60px;
}
.react-datepicker__navigation--next {

}
.react-datepicker__navigation--previous {

}

.tableDeleteButton,.tableEditButton,.tableResetPassButton {
 display: none;
}
.ReactTable .rt-tbody .rt-tr-group:hover .tableEditButton,
.ReactTable .rt-tbody .rt-tr-group:hover .tableResetPassButton,
.ReactTable .rt-tbody .rt-tr-group:hover .tableDeleteButton{
 display: inline-block;
}
.userDetailsForm {
  padding: 30px;
}
.saveUserButton {
  display: inline-block;
  font-size: 10pt;
  font-weight: 500;
  border-radius: 12px;
  border-radius: 10px;
  border-width: 1px;
  border-color: #ced4da !important;
  box-shadow:none !important;
}
.cancelUserButton {
  display: inline-block;
  font-size: 10pt;
  font-weight: 500;
  border-radius: 12px;
  border-radius: 10px;
  border-width: 1px;
  border-color: #ced4da !important;
  box-shadow:none !important;
}

.formSettings {
  padding: 30px;
}
h4 {
  font-size: 16px;
}
.saveSettingsButton {
  display: inline-block;
  font-size: 10pt;
  font-weight: 500;
  border-radius: 12px;
  border-radius: 10px;
  border-width: 1px;
  border-color: #ced4da !important;
  box-shadow:none !important;
}

/* Grand total BOX */
.reportTotalBox {
  width: 220px;
  height: 40px;
  left: 50%;
  bottom: 30px;
  margin-top: -20px;
  margin-left: -110px;
}
.overlayLabelContainer {
  padding-top: 8px;
}
.overlayTotalLabel {
  font-weight: light;
  font-size: 14px;
  text-align: center;

}
.overlayTotalValue {
  font-weight: 200;
  font-size: 14px;
  text-align: center;
}

.filterContainer {
    margin: 0;
    max-width: 100%;
}
.yearButton {
  margin-top: 2px;
}
.yearButton, .monthButton{
  margin-right: 15px !important;
  border-radius: 15px !important;
  padding: 0px 15px 0px 15px !important;
  background-color: white;
  color: black;
  font-size: 14px;
  font-weight: 500;
  border-color: #F0F0F0;
  box-shadow: none !important;
}
.selectionBar {
  padding-top: 8px;
  padding-bottom: 8px;
  border-bottom-color: #D7D7D7;
  border-bottom-width: 2px;
  border-bottom-style: solid;
}
.goButton {
  font-size: 10pt;
  font-weight: 500;
  border-radius: 12px;
  padding: 0px 10px 0px 10px;
  border-radius: 10px;
  border-width: 1px;
  border-color: #ced4da !important;
  box-shadow:none !important;
}
.btn-primary:not(:disabled):not(.disabled).active, .btn-primary:hover, .btn-primary:not(:disabled):not(.disabled):active, .show>.btn-primary.dropdown-toggle {
  background-color: #EAA39D;
  border-color: #F0F0F0;
  color: black;
}

/* Table */
.ReactTable {
  border: none;
}

/* Grand total BOX */
.reportTotalBox {
  width: 220px;
  height: 40px;
  left: 50%;
  bottom: 30px;
  margin-top: -20px;
  margin-left: -110px;
}
.overlayLabelContainer {
  padding-top: 8px;
}
.overlayTotalLabel {
  font-weight: light;
  font-size: 14px;
  text-align: center;

}
.overlayTotalValue {
  font-weight: 200;
  font-size: 14px;
  text-align: center;
}

.NotFound {
  padding-top: 100px;
  text-align: center;
}
.authBox {
  width: 350px;
  margin-top: 100px;
}
.authLogo {
  height: 70px;
  margin-bottom: 20px;
}
.authLabel {
  margin-bottom: 15px;
}
.inputFields {
  height: 60px;
  font-weight: 500;
  border-radius: 10px;
  border-width: 2px;
  padding-left: 20px;
}
.inputFields::-webkit-input-placeholder {
  color: #BBBBBB;
}
.inputFields::-ms-input-placeholder {
  color: #BBBBBB;
}
.inputFields::placeholder {
  color: #BBBBBB;
}
.inputFields:active,  .inputFields:focus {
  box-shadow: 0 0 4px 2px #EAA39D !important;
  border-color: #BBBBBB;
}
.loginButton, .loginButton:hover, .loginButton:hover:disabled, .navLogoutButton:active:focus {
  height: 60px;
  font-weight: 500;
  font-size: 1em;
  color: black !important;
  border-radius: 10px;
  border-width: 2px;
  background-color: #EAA39D !important;
  border-color: #ced4da !important;
  box-shadow: none !important;
}
.loginButton:hover {
  background-color: #e88178 !important;
}

body {
  padding: 0;
  min-width: 800px;
}
.wrapper {
  display: flex;
  flex-flow: column wrap;
  font-weight: bold;
  text-align: center;
  height: 100vh;

}
.wrapper > * {
  padding: 10px;
  flex: 1 1 100%;
}

/* main container - container nav and navigation controller */
.main {
  display: flex;
  flex-flow: row wrap;
  text-align: left;
  background: white;
  flex: 1 0;
  padding: 0;
  order: 1;
}

/* #headers stuff */
.header {
  z-index: 999;
  width: auto;
  max-height: 90px;
  box-shadow: 0px 2px 0 0 rgba(138, 139, 138, 0.35);
  margin: 0;
}
.headerUserName {
  margin-right: 30px;
  color: gray;
}
.logo {
  background: url(/static/media/logo.c2cca9aa.png);
  background-size:     contain;                      /* <------ */
  background-repeat:   no-repeat;
  background-position: center center;
  float: left;
  width: 159px;
  height: 69px;
  margin-left: 20px;
}

/* NavBar sutff */
.nav { order: 0; }
.navbar {
  z-index: 998;
  min-width: 180px;
  margin-top: 2px;
  justify-content: flex-start;
  align-items: flex-start;
  background: white;
  box-shadow: 2px 0 0 0 rgba(138, 139, 138, 0.35);
}
.nav-item.active {
  text-decoration: underline;
  font-weight:500;
}
.nav-item:hover {
  text-decoration: underline;
  cursor: pointer;
}
.nav-item { font-weight: lighter; }
.navbar .nav-item,h3 { margin-left: 10px; }
.navbar h3 { margin-top: 5px; }
.navbar .nav-item { margin-top: 15px; }
.nav-span { flex-grow: 99; }
.navLogoutButton, .navLogoutButton:hover, .navLogoutButton:active:focus {
  background-color: transparent !important;
  border: none;
  color: black !important;
  margin-left: -15px;
  box-shadow: none !important;
}
/* Main app content */
.AppPageContainer{
  position: relative;
  flex: 1 0;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.alert {
  z-index: 9990;
  position: absolute;
  right: 50%;
  max-width: 300px;
  width: 300px;
  top:10px;
  margin-right: -150px;
  overflow: scroll;
  max-height: 100px;
}

/* general react table  */
.ReactTable .rt-tbody .rt-tr-group:hover .tableResetPassButton,
.ReactTable .rt-tbody .rt-tr-group:hover .tableEditButton,
.ReactTable .rt-tbody .rt-tr-group:hover .tableDeleteButton{
 display: inline-block;
 font-size: 10pt;
 font-weight: 500;
 border-radius: 12px;
 padding: 2px 10px 2px 10px;
 border-radius: 10px;
 border-width: 1px;
 border-color: #ced4da !important;
 box-shadow:none !important;
}
.ReactTable .rt-tbody .rt-tr-group:hover .tableResetPassButton {

}
.tableDeleteButton,.tableEditButton,.tableResetPassButton {
 display: none;
}
.ReactTable .rt-tbody .rt-tr-group:hover .tableEditButton,
.ReactTable .rt-tbody .rt-tr-group:hover .tableResetPassButton,
.ReactTable .rt-tbody .rt-tr-group:hover .tableDeleteButton{
 display: inline-block;
}
.ReactTable .rt-thead .rt-th, .ReactTable .rt-thead .rt-td {
  border-right: none;
  margin-top: 10px;
  margin-bottom: 10px;
  font-weight: 600;
}
.ReactTable .rt-th, .ReactTable .rt-td {
  font-weight: normal;
  text-align: center;
}
.ReactTable .rt-tbody .rt-td {
  border-right: none;
}

