.tableDeleteButton,.tableEditButton,.tableResetPassButton {
 display: none;
}
.ReactTable .rt-tbody .rt-tr-group:hover .tableEditButton,
.ReactTable .rt-tbody .rt-tr-group:hover .tableResetPassButton,
.ReactTable .rt-tbody .rt-tr-group:hover .tableDeleteButton{
 display: inline-block;
}
.userDetailsForm {
  padding: 30px;
}
.saveUserButton {
  display: inline-block;
  font-size: 10pt;
  font-weight: 500;
  border-radius: 12px;
  border-radius: 10px;
  border-width: 1px;
  border-color: #ced4da !important;
  box-shadow:none !important;
}
.cancelUserButton {
  display: inline-block;
  font-size: 10pt;
  font-weight: 500;
  border-radius: 12px;
  border-radius: 10px;
  border-width: 1px;
  border-color: #ced4da !important;
  box-shadow:none !important;
}
