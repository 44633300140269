/* ADD BUTTON IN GAUGE */
.addLogActionContainer {
	width: 35px;
}
.addLogButton {
  width: 35px;
  height: 35px;
  border-radius: 18px;
  font-size: 20px;
  padding: 0;
  box-shadow: -2px 0px 0 0 rgba(153, 153, 153, 0.35), 2px 0px 0 0 rgba(153, 153, 153, 0.35), 0px 2px 0 0 rgba(153, 153, 153, 0.35), 0px -2px 0 0 rgba(153, 153, 153, 0.35);
  background-color: #ffffff;
  color: #959494;
	text-align: center;
}
.addLogButtonText {
  text-align: center;
	margin-top: 3px;
	font-weight: 500;
	font-size: 0.8em;
}
/* SUBMISSION BOX */
.logSubmissionBox {
	top: 400px;
	width: 220px;
	height: 120px;
	right: 30px;
}
.logBoxGrandTotalContainer {
	margin-top: 10px;
}
.logBoxGrandTotalLabel {
	font-weight: light;
	font-size: 14px;
}
.logBoxGrandTotalPriceLabel {
	font-weight: 200;
	font-size: 14px;
}
.logBoxDateContainer {
	margin-top: 10px;
}
.logBoxSubmissionLabel {
	font-weight: 200;
	font-size: 14px;
	padding-right: 0px;
}
.logBoxSubmissionDateLabel {
	font-weight: 200;
	padding: 0;
	padding-right: 10px;
}
.logBoxSubmissionDateButton, .logBoxSubmissionDateButton:hover {
	border: none;
	text-decoration: underline;
	padding: 0;
	font-size: 14px;
	margin-bottom: 4px;
}
.logBoxButtonContainer {
	margin-top: 10px;
}
.logBoxSubmissionButton {
	display: inline-block;
	font-size: 10pt;
	font-weight: 500;
	border-radius: 12px;
	padding: 2px 10px 2px 10px;
	border-radius: 10px;
	border-width: 2px;
	background-color: #EAA39D;
	height: 30px;
	border-color: #ced4da !important;
	box-shadow:none !important;
}
/* Date picker */
.react-datepicker__day-name {
	display: none !important;
}
.react-datepicker__month {
	display: none;
}
.react-datepicker__header {
	width: 158px;
	height: 60px;
	border: none;
}
.react-datepicker {
	width: 160px;
	height: 60px;
}
.react-datepicker__navigation--next {

}
.react-datepicker__navigation--previous {

}
