/* Grand total BOX */
.reportTotalBox {
  width: 220px;
  height: 40px;
  left: 50%;
  bottom: 30px;
  margin-top: -20px;
  margin-left: -110px;
}
.overlayLabelContainer {
  padding-top: 8px;
}
.overlayTotalLabel {
  font-weight: light;
  font-size: 14px;
  text-align: center;

}
.overlayTotalValue {
  font-weight: 200;
  font-size: 14px;
  text-align: center;
}
